<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_statuses") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="status"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="status.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(changeable)="data">
                <feather-icon
                  :icon="data.item.changeable == 1 ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(spv_changeable)="data">
                <feather-icon
                  :icon="data.item.spv_changeable == 1 ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(active)="data">
                <feather-icon
                  :icon="data.item.active == 1 ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  boundary="viewport"
                  :disabled="data.item.is_system == 1 ? true : false"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    status.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="status.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- category -->
          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              :label="lang('t_category')"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="category"
                v-model="currentData.status_category_id"
                :options="categories"
                :placeholder="lang('t_category')"
                :reduce="(val) => val._id"
                label="display_name"
                value="_id"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- categoryName -->
          <validation-provider
            #default="validationContext"
            name="categoryName"
            rules="required"
          >
            <b-form-group
              :label="lang('t_statusName')"
              label-for="categoryName"
            >
              <b-form-input
                id="categoryName"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Checkboxes -->
          <b-row>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.changeable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_changeable") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.spv_changeable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_onlySPVCanChange") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.show_in_dashboard"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_showInDashboard") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.can_transfer"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_canTransfer") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.play_sound"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_playSound") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-model="currentData.active"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_active") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="9">
              <b-form-checkbox
                v-model="currentData.trigger_on_crm_card_open"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_triggerOnCrmCardOpen") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    BContainer,
    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.status.slice(start, end);
    },

  },
  data() {
    return {
      search_category: "",
      search_status: "",
      selected_status: {},
      selected_category: {},
      categories: [],
      status: [],
      statu_groups: [
        { key: "BREAK", value: globalThis._lang("t_break") },
        { key: "LOGIN", value: globalThis._lang("t_online") },
        { key: "LOGOUT", value: globalThis._lang("t_offline") },
        { key: "OTHER", value: globalThis._lang("t_other") },
      ],
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        status_category_id: "",
        display_name: "",
        internal_name: "",
        changeable: false,
        spv_changeable: false,
        active: false,
        is_system: false,
        show_in_dashboard: false,
        can_transfer: false,
        play_sound: false,
        trigger_on_crm_card_open: false,
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_statusName"),
          sortable: true,
        },
        {
          key: "changeable",
          label: globalThis._lang("t_changeable"),
          sortable: true,
        },
        {
          key: "spv_changeable",
          label: globalThis._lang("t_onlySPVCanChange"),
          sortable: true,
        },
        { key: "active", label: globalThis._lang("t_active"), sortable: true },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
      records: [],
    };
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/Status`)).data;
      console.log("Status", response);
      this.categories = response.categories;
      this.status = response.status.filter((e) => !e.is_system);
      this.is_progress = false;
    },

    check_data() {
      if (
        this.currentData.status_category_id.trim() == "" ||
        this.currentData.display_name.trim() == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        if (
          this.status.find(
            (e) => e.display_name == this.currentData.display_name
          ) != undefined
        ) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        }

        response = (
          await this.$http_in.post(`/optmgr/v1/Status`, {
            StatusInfo: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/Status`, {
            StatusInfo: JSON.stringify(this.currentData),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      try {
        this.is_progress = true;
        var response = (
          await this.$http_in.delete(`/optmgr/v1/Status/${this.currentData.display_name}`)
        ).data;
        this.is_progress = false;

        if (response && response.acknowledged) {
          this.$swal({
            icon: "success",
            title: this.lang("t_processSuccess"),
            text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }

      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        status_category_id: "",
        display_name: "",
        internal_name: "",
        changeable: false,
        spv_changeable: false,
        active: false,
        is_system: false,
        show_in_dashboard: false,
      };
    },
    updateSelected(item) {
      console.log("item", item);
      this.currentData = {
        ...item,
      };
      console.log("currentData", this.currentData);
      this.isEdit = true;
      this.modal_record_details = true;
      //this.isAddNewUserSidebarActive = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);
    const keyOptions = [
      { label: "Key1", value: "1" },
      { label: "Key2", value: "2" },
      { label: "Key3", value: "3" },
      { label: "Key4", value: "4" },
    ];

    const categoryOptions = [
      { label: "Kat1", value: "1" },
      { label: "Kat2", value: "2" },
      { label: "Kat3", value: "3" },
      { label: "Kat4", value: "4" },
    ];
    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      keyOptions,
      categoryOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
